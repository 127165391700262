<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <ion-header translucent>
        <ion-toolbar>
          <ion-title class="title">Image Preview</ion-title>
          <ion-buttons slot="end">
            <ion-button class="close-button" @click="dismissModal()">
              <ion-icon :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <div class="d-flex justify-content-center align-items-center pb-3 px-3">
        <div class="h-100 w-100">
          <div class="d-flex justify-content-center align-items-center h-75 w-100 mb-3">
            <img loading="lazy" :src="resizeUpload(imageUrl, '1280w')" class="visual-prev-img mt-3" />
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { closeOutline } from 'ionicons/icons';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  imageUrl: {
    type: String,
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const isOpen = toRef(props, 'isOpen');
const emit = defineEmits(['dismiss']);
const dismissModal = async () => {
  emit('dismiss');
};
</script>

<style lang="sass" scoped>
.visuals
  height: 350px
@media (max-width: 480px)
  .visuals
    height: 200px

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 98vh
  overflow: auto
  overflow-x: hidden

.reaction-count
  font-size: 18px
  font-weight: bold

.visual-prev-img
  max-width: 100%
  max-height: 400px
.close-bottom
  position: absolute
  bottom: 1.5rem
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  width: 100%
  text-align: center
  div
    display: inline-block
.close-button
  width: 50px
.bp
  position: absolute
  top: -35px
  z-index: 11

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
</style>
